<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<partidaequipamiento-modals
			:modelName="modelName"
		></partidaequipamiento-modals>
	</div>
</template>

<script>
import partidaequipamientoActions from './PartidaequipamientoActions';
import partidaequipamientoModals from './PartidaequipamientoModals.vue';

export default {
	name: 'partidaequipamiento-grid',
	components: {
		'partidaequipamiento-modals': partidaequipamientoModals
	},
	data() {
		return {
			modelName: 'partidaequipamiento',
			actions: partidaequipamientoActions.gridactions,
			modelColumnDefs: {
			}
		}
	},
	created(){
	}
}
</script>
